.cta-landing-page {
  .cta-btn {
    width: 300px;
    height: 60px;
    margin: 30px 0 10px;
    padding: 14px 16px;
    border-radius: 3px;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(130, 175, 255, 0.25);
    background-color: var(--carolina-blue);
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.25px;
    text-align: center;
    color: var(--white);
    @media (max-width: 768px) {
      margin: 20px 0 5px;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .download {
    display: flex;
    margin: 30px 0;
    justify-content: center;

    @media (min-width: 769px) {
      justify-content: left;
    }

    @media (min-width: 1025px) {
      display: none;
    }

    img {
      width: 155px;
      padding-right: 5px;
    }
  }
}