.verify-email {
  display: flex;
  justify-content: center;
  align-items: center;

  .verifying {
    text-align: center;

    h3 {
      margin-top: 10px;
    }
  }

  .verification-status {
    text-align: center;

    button {
      margin-top: 10px;

      a {
        color: #FFFFFF;
      }
    }
  }
}