.header-desktop {
  display: flex;
  height: 65px;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;
  border-bottom: 1px solid #e5e6eb;

  .user-menu {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 60px;

    a {
      margin: 0 15px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -.1px;
      color: var(--charcoal-grey);
    }
  }

  .user {
    margin-left: 30px;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    span {
      font-size: 13px;
      margin-left: 8px;
      color: var(--charcoal-grey);
    }
  }
}