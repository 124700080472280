.menubar {

    .elements-part {
      height: 80px;

      @media (max-width: 767px) {
        height: 60px;
      }

      .menubar-logo img {
        width: 153px;
        height: 25px;
      }
      .menubar-actions {        
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 766px) {
            display: none;
        }

        a {
          font-family: Roboto;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.1px;
          color: var(--charcoal-grey);
        }
        button {
          width: 90px;
          height: 40px;
          border-radius: 3px;
          border: none;
          box-shadow: 0 2px 4px 0 rgba(130, 175, 255, 0.25);
          background-color: var(--carolina-blue);
          font-family: Roboto;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.29px;
          text-align: center;
          color: var(--white);
        }
      }
      i {
        font-size: 30px;
        text-align: right;
        cursor: pointer;

        @media (min-width: 767px) {
            display: none;
        }
       }
    }  
    .mobile-menubar {
      position: absolute;
      width: 100%;
      top: 0;
      background-color: var(--white);
      display: none;
      z-index: 10;
      height: 100vh;

      .mobile-menu-header {
        position: relative;

        .menubar-logo {
          padding: 20px;
        }

        .close-btn {
          position: absolute;
          top: 22px;
          right: 20px;
          border: 0;
          background: none;

          img {
            width: 24px;
          }
        }
      }

      .menu-top {
        margin-top: 50px;
        ul {
          list-style: none;
          padding-left: 0;

          li {
            font-family: Roboto;
            font-size: 1.6rem;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.1px;
            padding: 15px 20px;

            a {
              color: var(--dark);
            }
          }
          li:hover {
              background-color: black;
              color: white;
          }
          @media (min-width: 767px) {
              display: none;
          }
        }
      }

      .sign-up {
        width: 200px;
        position: absolute;
        bottom: 43px;
        left: 20px;

        a {
          color: var(--white);
          font-size: 1.3rem;
        }
      }
    }  
}