.collection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
}

.collection-actions {
  display: flex;
  justify-content: space-around;
  width: 200px;
}