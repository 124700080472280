.action-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: fit-content;
  max-width: 80px;

  span {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .25px;
    text-align: center;
    color: #3c4146;
  }
}