.pricing-card {
  text-align: center;
  width: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(103, 114, 121, 0.1), 0 10px 15px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 15px;

  @media (min-width: 768px) {
    width: 48%;
  }

  @media (min-width: 1024px) {
    width: 230px;
  }

  @media (min-width: 1130px) {
    width: 260px;
  }

  .top {
    background-color: var(--white);
    padding: 36px 40px 51px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @media (min-width: 1024px) {
      padding: 36px 20px 51px;
    }

    @media (min-width: 1130px) {
      padding: 36px 40px 51px;
    }
  }

  .bottom {
    position: relative;
    padding: 32px 0;
    color: var(--white);
    background-image: linear-gradient(to top, #96bcff, var(--carolina-blue));
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .frequency {
    margin: 0 0 8px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.25px;
    text-align: center;
    color: var(--charcoal-grey);
  }

  .price {
    margin: 8px 0 0;
    font-size: 40px;
    font-weight: 500;
    line-height: 0.55;
    letter-spacing: -1.67px;
    text-align: center;
    color: var(--carolina-blue);
  }

  .savings {
    background-color: #3c78d7;
    position: absolute;
    margin: 0 auto;
    top: -9px;
    left: 0;
    right: 0;
    width: 113px;
    height: 32px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 5px;
    padding: 5px;
  }

  .trial {
    font-weight: bold;
  }

  .due {
    font-size: 12px;
  }

  .cta {
    margin-top: 16px;
  }

  &.active, &:hover {
    .price {
      color: var(--pinkish-orange);
    }
  
    .bottom {
      background-image: linear-gradient(to top, #ff9463, var(--pinkish-orange));
    }

    .savings {
      background-color: #e34218;
    }
  }
}