.new-member-modal {
  .form-row {
    margin-bottom: 0;
    padding: 8px 0;
    border-bottom: 1px solid #e5e6eb;
  }
  
  .header {
    font-size: 16px;
    padding-bottom: 2px;
    font-weight: 600;
  }

  .form-label {
    font-size: 1rem;
  }

  .select-member {
    width: 100%;
  }
}
