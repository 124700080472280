.search-bar {
  margin-right: 10px;
  display: flex;
  position: relative;
  max-width: 445px;

  .search-input {
    width: 400px;
    height: 36px;
    padding: 8px 40px 8px 20px;
    border-radius: 3px;
    background-color: var(--ice-blue);
    border: 1px solid #e2e5ec;
    position: relative;
  }

  .search-btn {
    position: absolute;
    right: 0;
  }
}