.login-container {
  color: var(--charcoal-grey);
  font-size: 15px;
  a {
    color: var(--carolina-blue);
  }

  .title {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  .sub-title {
    text-align: center;
  }

  .login-form {
    margin-top: 8px;
  }

  .error {
    color: var(--pinkish-orange);
    font-size: 16px;
    margin: 8px 0;
  }

  .password-field {
    position: relative;
    a {
      position: absolute;
      right: 0;
      top: -28px;
    }
  }

  .form-control {
    height: 48px;
    &.is-invalid {
      border: 2px solid var(--pinkish-orange);
      background-image: url(/img/basic-alert.png);

      &:focus {
        border: 2px solid var(--pinkish-orange);
      }
    }
  }

  .inValid-feedback {
    font-size: 12px;
    color: var(--pinkish-orange);
    padding: 0 0 0 16px;
  }

  .login-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}