.profile-field {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .edit-save {

    &.btn {
      background-color: transparent;

      &:hover, &:focus, &:active {
        background-color: transparent;
      }
    }
  }

  .profile-input-field {
    flex: 1;
  }
}