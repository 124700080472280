.notification {
  font-family: Roboto;
  font-size: 15px;
  background-color: var(--carolina-blue);
  color: var(--charcoal-grey);
  padding: 15px 10px;
  text-align: center;
  border-bottom: 1px solid #e5e6eb;

  a {
    color: var(--grey-c);
    font-weight: 600;
  }
}