.organizations {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .organizations-search {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
    }

    .search-bar {
      margin-right: 10px;
    }

    .search-input {
      width: 360px;
      height: 36px;
      padding: 8px 10px 8px 20px;
      border-radius: 3px;
      background-color: var(--ice-blue);
      border: 0;
      position: relative;
    }
  }
  .organizations-results {
    margin-top: 80px;

    .organizations-grid {
      font-size: 1em;

      .col-name {
        img {
          width: 24px;
        }
      }
    }
  }
}

.grid-actions {
  width: 80px;
  display: flex;
  justify-content: space-between;
}
