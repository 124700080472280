.brand {
  height: 64px;
  padding: 19px 36px 23px 51px;
  background-image: linear-gradient(to left, #ff4b2d, #ff7b4a);
}

.menu-container {
  background-color: var(--ice-blue);
  height: calc(100vh - 64px);
  overflow-y: scroll;
}
