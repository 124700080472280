.member-subscription {
  max-width: 548px;
  width: 100%;
  margin: 0 auto 30px;

  .row {
    display: flex;
    height: 48px;
    border-bottom: 1px solid #e5e6eb;
    align-items: center;

    .label {
      color: #91a0aa;
    }

    .col1 {
      width: 30%;
    }

    .col2 {
      width: 40%;
    }

    .col3 {
      width: 30%;
      text-align: right;
    }
  }

  .member-status {
    margin: 0 5px;
  }

  .membership-expired {
    color: red;
  }

  .text-align-center {
    text-align: center;
  }

  .full-width-field {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 300px;
      margin-left: 10px;
    }
  }
}