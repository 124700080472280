.verified-coupon {
  height: 26px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #3c4146;
}

.plan-test-page {
  .row.header-section {
    & > img {
      width: 100%;
    }
    @media (max-width: 768px) {
      & > img {
        height: 400px;
      }
    }
    .header-content {
      position: absolute;
      width: 100%;
      left: 0px;
      top: 40px;
      padding: 0px 40px;

      h3 {
        font-family: Roboto;
        font-size: 34px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.35px;
        text-align: center;
        color: var(--white);
      }
      h4 {
        font-family: Roboto;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: -0.25px;
        text-align: center;
        color: var(--white);
      }
      @media (max-width: 768px) {
        h3 {
          font-size: 25px;
        }
        h4 {
          display: none;
        }
      }
      .coupon-section {
        margin: 40px auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 388px;
        height: 56px;
        border-radius: 5px;
        background-color: var(--white);

        input {
          width: 260px;
          height: 40px;
          border-radius: 3px;
          border: solid 1px var(--light-blue-grey);
        }
        button {
          width: 104px;
          height: 40px;
          border-radius: 3px;
          box-shadow: 0 2px 4px 0 rgba(130, 175, 255, 0.25);
          background-color: var(--carolina-blue);
          font-family: Roboto;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.29px;
          text-align: center;
          color: var(--white);
        }
      }
      @media (max-width: 768px) {
        .coupon-section {
          width: 300px;
        }
      }
    }
    @media (max-width: 768px) {
      .header-content {
        padding: 0px;
      }
    }
  }

  .row.select-plan-section {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 280px;
    width: 100%;

    .plans-container {
      margin: auto;
      display: flex;

      .each-plan {
        width: 260px;
        height: 340px;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(103, 114, 121, 0.1),
          0 10px 15px 0 rgba(0, 0, 0, 0.08);
        background-color: var(--white);
        margin: 12px;

        &:hover {
          .kt-pricing-v1__header {
            .plan-price {
              color: var(--pinkish-orange);
            }
          }
          .kt-pricing-v1__body {
            background-image: linear-gradient(
              to top,
              #ff9463,
              var(--pinkish-orange)
            );
            .kt-pricing-v1__button {
              color: var(--pinkish-orange);
              background-color: var(--white);
            }
          }
          .save-percentage {
            background-color: #e34218;
          }
        }
        .kt-pricing-v1__header {
          height: 50%;

          .plan-name {
            font-family: Roboto;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.25px;
            text-align: center;
            color: var(--charcoal-grey);
          }
          .plan-price {
            font-family: Roboto;
            font-size: 40px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.55;
            letter-spacing: -1.67px;
            text-align: center;
            color: var(--carolina-blue);
          }
        }
        .kt-pricing-v1__body {
          height: 50%;
          flex-direction: column;
          border-radius: 5px;
          background-image: linear-gradient(
            to top,
            #96bcff,
            var(--carolina-blue)
          );
          justify-content: space-evenly;
          padding: 30px;

          .day-free-trial {
            font-family: Roboto;
            font-size: 15px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.47;
            letter-spacing: -0.25px;
            text-align: center;
            color: var(--white);
          }
          .price-trial {
            opacity: 0.75;
            font-family: Roboto;
            font-size: 11px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: -0.18px;
            text-align: center;
            color: var(--white);
          }
          .kt-pricing-v1__button {
            width: 180px;
            height: 40px;
            border-radius: 3px;
            border: solid 1px var(--white);
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.29px;
            text-align: center;
            color: var(--white);
            cursor: pointer;
          }
        }
        .save-percentage {
          position: relative;
          top: calc(-50% - 16px);
          margin: auto;
          text-align: center;
          width: 113px;
          height: 32px;
          border-radius: 5px;
          background-color: #3c78d7;

          p {
            font-family: Roboto;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.47;
            letter-spacing: -0.25px;
            text-align: center;
            color: var(--white);
          }
        }
      }
    }
    .plans-explanation {
      p {
        margin: 50px 0px;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.25px;
        text-align: center;
        color: var(--charcoal-grey);
      }
      .plans-explanation-details {
        border-bottom: solid 1px #e5e6eb;
        padding: 10px;

        span {
          margin-left: 16px;
          width: 496px;
          height: 32px;
          font-family: Roboto;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: -0.25px;
          color: var(--charcoal-grey);
        }
      }
    }
  }
}
