.user-layout-wide {

  .content-container {
    max-height: calc(100vh - 65px);
    overflow-y: scroll;

    .content {
      padding: 33px 38px 33px 42px;
      max-width: 980px;
      margin: 0 auto;
    }
  }
}

