@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
font-family: "Poppins", sans-serif;

.background {
  height: 100vh;
}
.back {
  height: 100vh;
}

.kt-portlet {
  border-radius: 5px;
  box-shadow: 2px 2px 17px 6px #cdcdcd80;
}
.signup {
  padding-left: 0px !important;
  height: 720px;
}
.signup-btn {
  margin: auto 0px auto auto !important;
}
.login-btn {
  background: #fa694b;
  border-color: #fa694b;
}
.form-width {
  width: 50%;
  margin: auto;
}
.kt-form {
  padding: 0px 20px 20px 20px;
}
.btn-orange {
  background-color: #fa694b;
  border-color: #fa694b;
}
.signup-btn {
  margin: auto 0 auto auto !important;
}
.change-pass-btn {
  margin: auto 0 auto !important;
}
#kt_header {
  background-color: white;
  border-bottom: 1px solid #e5e6eb;
}
.btn-orange:hover {
  background-color: #fa694b;
  border-color: #fa694b;
}
.signin,
.error-text {
  color: #fa694b;
  margin: auto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.form-group .form-text {
  font-size: 1rem;
}
.form-group label {
  font-size: 1.2rem;
}
.add {
  border: 1px solid #0004;
}
.add:hover {
  background-color: #fa694b1a !important;
}
.add:focus {
  background-color: #fa694b1a !important;
}
.addOrg {
  margin: auto 0 auto auto;
}

#basic-addon2 {
  background-color: #f45944;
  border: 1px solid #f45944;
}

.badge-light {
  padding: 0.2rem 0.5rem;
}
.list ul li {
  list-style: none !important;
}
.tab-header {
  font-size: 18px !important;
  background-color: #fff !important;
}
.active {
  color: #fa694b !important;
  border-bottom: 1px solid #fa694b;
  border-radius: 0px !important;
}
.tab-pane {
  color: #000 !important;
  border: none;
}
.kt-widget__bottom {
  border-top: none !important;
}

.total {
  font-size: 18px !important;
}
.kt-header__topbar-welcome,
.kt-header__topbar-username {
  font-size: 14px !important;
}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon:hover {
  background-color: #f45944;
}
.class-card-details {
  padding: 0px 25px 25px;
}
.rounded {
  border-radius: 50px !important;
  background-color: #f8e71c !important;
  color: #000 !important;
}
.disable-rounded {
  border-radius: 50px !important;
  background-color: #93989e !important;
  color: #000 !important;
}
@media (max-width: 1024px) {
  #kt_subheader {
    width: 100%;
    margin: auto;
  }
}
.btn.btn-default:hover {
  color: black;
  background: #fa694b;
  border-color: #fa694b;
}
.kt-widget__title:hover {
  color: #000 !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#btn2 {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.btn-orange {
  background-color: #fa694b;
  border-color: #fa694b;
}

.dropdown-menu.dropdown-menu-sm {
  width: auto;
}

.signout-btn {
  padding: 0.3rem 0.8rem !important;
  font-size: 0.8rem !important;
}
.kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon {
  transition: all 0.3s;
  background-color: #fa694b;
}
.textDetails {
  background-color: #fa694b;
  height: 120px;
}
.col-12.textHeading {
  height: 150px;
}

.detailed {
  line-height: 2.5;
}
.carousel-control-next-icon2 {
  /* top:-100%; */
  background-color: brown !important;
}
.navigationCarousel {
  background-color: #fa694b;
}
.controlCarousel1 {
  left: 80%;
}
.month {
  padding: 25px;
}
.h5plan {
  color: #fa694b;
}
.planborder {
  border: 1px solid #cdcdcd;
  border-radius: 5px;
}
.planborder:hover {
  border: 1px solid #fa694b;
}

.popular {
  background-color: #fa694b1a;
  margin: -19px;
}
.popularHead {
  border-radius: 50px;
  background-color: rgb(124, 124, 243);
  color: white;
  position: relative;
  top: -10px;
}

.fclogin {
  height: 690px;
}

.bg-color {
  background-color: #fef0ed;
  border-radius: 5px;
}

#kt_login_form1 input {
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  background-color: transparent;
}
.redeem-btn {
  background: #fa694b;
  border-color: #fa694b;
  color: #fff;
}
.redeem-btn:hover {
  background: #fa694b;
  border-color: #fa694b;
  color: #fff;
}

.creditCard {
  background-color: white;
}

.nav-pills .nav-item .nav-link:active,
.nav-pills .nav-item .nav-link.active,
.nav-pills .nav-item .nav-link.active:hover {
  background-color: white;
  color: #ffffff;
}
.rounded-lg {
  border-radius: 1rem;
}

.nav-pills .nav-link {
  color: #555;
}

.nav-pills .nav-link.active {
  color: #fff;
}

.landinglogo {
  width: 120%;
  padding: 30px 0 0 250px;
  margin-left: 50px;
}
.view {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.breadcrumb {
  background-color: #fa694b1a;
}

.link_dom {
  color: #fa694b;
}
.link_dom:hover {
  color: #fa694b;
}
.btn-primary {
  background-color: #fa694b;
  border: #fa694b;
}
.centered {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-outline-custom {
  padding: 10px;
  border-radius: 30px;
  border: 2px solid white !important;
  margin: 10px;
}

.getStarted {
  background-color: #4d3c56;
}

.selected_navBar {
  background: #fa694b3a;
}

.fa-arrow-right {
  margin: auto 10px auto auto !important;
}

.profile-btn {
  font-size: 20px;
}

@media (max-width: 991px) {
  .membership {
    text-align: center;
  }
  .limited-access {
    justify-content: center;
  }
  .limited-parent {
    margin: auto;
    justify-content: center;
  }
}

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -2px;
  width: 0px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 0%;
  vertical-align: middle;
}
.rc-slider-handle {
  position: absolute;
  width: 0px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 0%;
  border: solid 2px #fa694b;
  background-color: #fff;
  touch-action: pan-x;
}

.btnLanding:hover {
  background-color: #fa694b;
  border-color: #fa694b;
  color: #fa694b;
}
.landingBtn {
  background: #fa694b;
  border-color: #fa694b;
}
.landingBtn:hover {
  background: #fff;
  border-color: #fa694b;
  color: #fa694b;
  font-weight: 500;
}
.modal-confirm .modal-header {
  background: #47c9a2;
  border-radius: 5px 5px 0 0;
}
.modal-confirm .modal-content {
  border-radius: 5px;
  border: none;
}
.modal-confirm .modal-ok {
  width: 100px;
  background: #47c9a2;
  color: black;
}
.modal-confirm .modal-cancel {
  margin-left: 10px;
  width: 100px;
  border: 1px solid black;
  color: black;
}
.modal-warning .modal-ok {
  width: 100px;
  background: #fa694b;
  color: black;
}
.modal-warning .modal-cancel {
  margin-left: 20px;
  width: 100px;
  border: 1px solid black;
  color: black;
}
.modal-warning .modal-header {
  background: #fa694b;
  border-radius: 5px 5px 0 0;
}
.modal-warning .modal-content {
  border-radius: 5px;
  border: none;
}
.modal-warning .modal-body {
  font-size: 19px;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #3c4146;
}
.modal-confirm .modal-content {
  border-radius: 5px;
  border: none;
}
.modal-content .form-control {
  height: auto !important;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.planWrapper {
  padding-left: 0px;
}

.planWrapperLeft {
  padding-left: 260px;
}

.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #f02a01;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #f02a01;
}
.carousel .slide {
  background: white;
}

.YourHighlightClass {
  background-color: yellow !important;
}

/* Profile Style */

/* .profileBody{
    background: -webkit-linear-gradient(left, #fc4a1a, #f7b733); } */

.emp-profile {
  padding: 3%;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 0.5rem;
  background: #fff;
}
.profile-img {
  text-align: center;
}
.profile-img img {
  width: 70%;
  height: 100%;
}
.profile-img .file {
  position: relative;
  overflow: hidden;
  margin-top: -20%;
  width: 70%;
  border: none;
  border-radius: 0;
  font-size: 15px;
  background: #212529b8;
}
.profile-img .file input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}
.profile-head h5 {
  color: #333;
}
.profile-head h6 {
  color: #fa694b;
}
.profile-edit-btn {
  border: none;
  border-radius: 1.5rem;
  width: 70%;
  padding: 2%;
  font-weight: 600;
  color: #6c757d;
  cursor: pointer;
}
.proile-rating {
  font-size: 12px;
  color: #818182;
  margin-top: 5%;
}
.proile-rating span {
  color: #495057;
  font-size: 15px;
  font-weight: 600;
}
.profile-head .nav-tabs {
  margin-bottom: 5%;
}
.profile-head .nav-tabs .nav-link {
  font-weight: 600;
  border: none;
}
.profile-head .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #fa694b;
}
.profile-head .nav-tabs .nav-link {
  color: black;
}
.profile-work {
  padding: 14%;
  margin-top: -15%;
}
.profile-work p {
  font-size: 12px;
  color: #818182;
  font-weight: 600;
  margin-top: 10%;
}
.profile-work a {
  text-decoration: none;
  color: #495057;
  font-weight: 600;
  font-size: 14px;
}
.profile-work ul {
  list-style: none;
}
.profile-tab label {
  font-weight: 600;
}
.profile-tab p {
  font-weight: 600;
  color: #fa694b;
  text-transform: capitalize;
}

.pagebtn:active {
  background: olive;
}

#page-numbers > li {
  cursor: pointer;
}

#page-numbers > li:focus {
  background-color: red;
}

.braintree-heading {
  color: red;
  display: none;
  text-align: center;
  padding: 20px;
}

.braintree-methods-initial {
  margin: 100px;
}
.active,
.page-item:hover {
  color: #fa694b;
}

.kt-menu__submenu .kt-menu__subnav li span {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 10;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #91a0aa;
  margin-left: 8px;
}

.kt-menu__submenu .kt-menu__subnav li span.selected {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #3b4145;
  margin-left: 8px;
}

.content-subheader-title {
  height: 26px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #3c4146;
}

.content-subheader-add {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.content-subheader-add span {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: center;
  color: #3c4146;
}

.navbar-hided {
  padding-left: 30px !important;
}

.go-back {
  cursor: pointer;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #3c4146;
  display: flex;
  align-items: center;
}

.uploadspan {
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #3c4146;
  cursor: pointer;
}

/* TextDetails */
.td-json-editor {
  width: 100%;
  height: 25px;
}

.td-json-editor span {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: #3c4146;
}
/*  */
.kt-menu__item:hover {
  background-color: white !important;
  color: white;
}

.g-search-button {
  width: 140px;
  height: 35px;
  margin-left: 10px;
}

.second-description-table th:nth-child(2),
.second-description-table tr td:nth-child(2) {
  max-width: 250px;
}

.subpayments-table {
  height: 300px;
}

.subpayments-table th:nth-child(4) {
  max-width: 70px;
}

.subpayments-table tbody tr td:nth-child(4) {
  text-align: right;
}

.searchbox {
  margin: 33px auto;
}

.goSignIn {
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-left: 40px;
  width: 100px;
  height: 30px;
  position: relative;
  background: #fa694b;
  padding-right: 15px;
}
.goSignIn:after {
  content: "";
  position: absolute;
  left: -15px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 15px solid #fa694b;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}
.goSignIn:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 15px solid transparent;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.profile-dropdown {
  border: 1px solid #e5e6eb !important;
}

.profile-dropdown li {
  padding: 10px;
}

.profile-dropdown img {
  margin-right: 15px;
}

.profile-dropdown li:first-child {
  border-bottom: 1px solid darkgrey;
}

.profile-dropdown span {
  font-size: 15px;
  font-family: Roboto;
}

.profile-dropdown ul li:first-child span {
  color: #3c4146;
}

.profile-dropdown ul li:nth-child(2) span {
  color: red;
}
select.form-control {
  height: auto;
  padding: 5px;
}

.kt-login-v2 {
  background-color: #fff;
}
.kt-login-v2 .kt-login-v2__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  padding: 2rem 3rem;
}

.kt-login-v2 .kt-login-v2__body {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 3rem;
}
.kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper {
  width: 50%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper .kt-login-v2__container {
  width: 100%;
  max-width: 500px;
  -webkit-box-shadow: 0px 0px 80px 0px rgba(77, 84, 124, 0.09);
  box-shadow: 0px 0px 80px 0px rgba(77, 84, 124, 0.09);
  border-radius: 0.6rem;
  background-image: linear-gradient(135deg, blue, green);
}
.kt-login-v2
  .kt-login-v2__body
  .kt-login-v2__wrapper
  .kt-login-v2__container
  .kt-login-v2__title {
  text-align: center;
}
.kt-login-v2
  .kt-login-v2__body
  .kt-login-v2__wrapper
  .kt-login-v2__container
  .kt-login-v2__title
  > h3 {
  color: lightgrey;
  margin-top: 3rem;
  font-size: 1.5rem;
}
.kt-login-v2
  .kt-login-v2__body
  .kt-login-v2__wrapper
  .kt-login-v2__container
  .kt-login-v2__form {
  padding: 3rem 4rem 2rem 4rem;
}
.kt-login-v2
  .kt-login-v2__body
  .kt-login-v2__wrapper
  .kt-login-v2__container
  .kt-login-v2__form
  .form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
}
.kt-login-v2
  .kt-login-v2__body
  .kt-login-v2__wrapper
  .kt-login-v2__container
  .kt-login-v2__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin-top: 3rem;
}
.kt-login-v2
  .kt-login-v2__body
  .kt-login-v2__wrapper
  .kt-login-v2__container
  .kt-login-v2__actions
  .btn {
  padding: 1rem 3rem;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
}


@media (max-width: 1024px) {
  .kt-login-v2 .kt-login-v2__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.5rem 2rem;
  }
  .kt-login-v2 .kt-login-v2__head .kt-login-v2__logo {
    margin-bottom: 2rem;
  }
  .kt-login-v2 .kt-login-v2__body {
    padding-top: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .kt-login-v2 .kt-login-v2__body .kt-login-v2__wrapper {
    padding-left: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .kt-login-v2
    .kt-login-v2__body
    .kt-login-v2__wrapper
    .kt-login-v2__container {
    margin-left: 0;
  }
  .kt-login-v2
    .kt-login-v2__body
    .kt-login-v2__wrapper
    .kt-login-v2__container
    .kt-login-v2__title {
    padding-top: 1rem;
  }
  .kt-login-v2
    .kt-login-v2__body
    .kt-login-v2__wrapper
    .kt-login-v2__container
    .kt-login-v2__form {
    padding: 3rem 2rem;
  }
  .kt-login-v2
    .kt-login-v2__body
    .kt-login-v2__wrapper
    .kt-login-v2__container
    .kt-login-v2__actions {
    padding: 0;
  }
  .kt-login-v2 .kt-login-v2__body .kt-login-v2__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    padding: 4rem 0;
  }
  .kt-login-v2 .kt-login-v2__body .kt-login-v2__image > img {
    width: 100%;
    max-width: 500px;
    height: 100%;
  }
}
.kt-login-v2 .kt-login-v2__body .kt-login-v2__image > img {
  width: 100%;
  max-width: 650px !important;
}
.kt-login-v2 .kt-login-v2__body {
  padding: 0px;
}

.inValid-feedback {
  color: var(--danger);
}
