.reset-notice-container {
  color: var(--charcoal-grey);

  a {
    color: var(--carolina-blue);
  }

  font-size: 15px;

  .title {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  .sub-title {
    text-align: center;
    margin: 18px 0;
    padding-bottom: 10px;
  }

  .center-text {
    text-align: center;
  }
}