.profile-payments {
  width: 100%;
  max-width: 830px;
  margin: 0 auto;

  .title {
    border-bottom: 1px solid #e5e6eb;
    padding-bottom: 8px;
    font-size: 1.7rem;
  }

  .payments-grid {
    font-size: 0.8em;
  }
}