.text-details {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;

  .header {
    display: flex;
    align-items: center;
    margin-left: -36px;
    margin-bottom: 15px;

    img {
      width: 31px;
      height: 45px;
    }

    h3 {
      margin: 10px;
      flex: 1;
    }
  }

  .form-row {
    margin-bottom: 0;
    padding: 8px 0;
    border-bottom: 1px solid #e5e6eb;

    label {
      font-size: 1em;
    }

    .audio-field {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .audio-input {
        display: none;
        position: relative;
      }

      .upload-btn {
        cursor: pointer;
        font-weight: 600;
      }
    }

    .full-width {
      width: 100%;
    }
  }
}