.mbd-subscription {
    .change-subscription {
        p {
          margin-left: 10px;
          height: auto;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.25px;
          color: #3c4146;
        }
        .cancel-subscription {
          background: #fa694b;
          color: white;
          font-weight: bold;
        }
        .ok-subscription {
          background: #fa694b;
          color: white;
          font-weight: bold;
        }
      }
}