
.sign-up {
  .condition-container {
    ul {
      li {
        font-size: 16px;
      }
    }
  }

  .condition-content {

    h3 {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.25px;
      text-align: center;
      color: var(--dark);
    }

    .sub-text {
      font-family: Roboto;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: -0.25px;
      text-align: center;
      color: var(--dark);
    }

    .user-selection {
      display: flex;
      justify-content: space-between;
      margin-top: 64px;
    }

    .user-radio {
      height: 185px;
      display: flex;
      flex-direction: column;
      border: 1px solid #cdd7dc;
      border-radius: 5px;
      flex: 1;
      justify-content: center;
      align-items: center;
      max-width: 48%;
      cursor: pointer;

      img {
        width: 64px;
      }
    }

    .condition-checkbox {
      margin: 26px 0 24px;
      padding: 0 20px 0 24px;
      border-radius: 5px;
      border: 1px solid #cdd7dc;
      min-height: 60px;
      display: flex;
      align-items: center;
    }

    .form-check {
      margin: 8px 0;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;

      input {
        width: 24px;
        height: 24px;
        border: 1px solid #cdd7dc;
        margin-top: -0.1rem;
      }
    }

    .form-check-label {
      margin-left: 24px;
      cursor: pointer;
    }

    .device-img {
      text-align: center;
      margin: 24px 0 48px;

      img {
        width: 187px;
      }
    }

    .prereq-item {
      display: flex;
      margin: 16px 0;

      .item {
        padding-left: 12px;
      }

      .title {
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: -0.25px;
        color: var(--dark);
      }

      .sub-title {
        font-family: Roboto;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.69;
        letter-spacing: -0.22px;
        color: var(--grey-a);
      }
    }
  }

  .condition-actions {
    margin: 30px 0 -20px;
    text-align: center;

    button {
      width: 60%;
      text-transform: uppercase;
      font-size: 15px;
    }
  }
}


