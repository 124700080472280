.hamburger-menu {
  position: relative;
  padding-top: 8px;

  button {
    width: 30px;
    height: 26px;
  }

  @mixin bar($top, $width) {
    background: #FFFFFF;
    display: block;
    position: absolute;
    top: $top;
    left: 0;
    height: 2px;
    min-height: 2px;
    width: $width;
    border-radius: 2px;
    transition: width 0.4s ease;
  }

  .trigger {
    @include bar(0, 100%);
    margin-top: 16px;

    &:before {
      content: "";
      @include bar(-8px, 100%);
    }

    &:after {
      content: "";
      @include bar(8px, 100%);
    }

    &.open {

      &:before {
        @include bar(-8px, 50%);
      }
  
      &:after {
        @include bar(8px, 75%);
      }
    }
  }
}

.menu-container-mobile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 240px;
  background-color: var(--ice-blue);
  height: 100vh;

  .secondary-menu {
    margin-top: 15px;
    border-top: 1px solid #e5e6eb;
  }
}
