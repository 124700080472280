.newsfeed-preview-modal {
  .newsfeed-preview-body {
    background-color: #f2f2f2;
    padding: 15px;
  }

  .newsfeed-preview-card {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 20px 0 15px;
    max-width: 360px;
    margin: 0 auto;
  }

  .newsfeed-title {
    font-size: 20px;
    padding: 0 15px;
    text-align: center;
  }

  .newsfeed-img {
    width: 100%;
  }

  .newsfeed-audio {
    text-align: center;
    margin: 10px 0;
  }

  .newsfeed-content {
    padding: 5px 15px;
    h2 {
      text-align: center;
    }
  }
}