.user-home {
  max-width: 548px;
  width: 100%;
  margin: 0 auto;
  font-family: Roboto;

  .title {
    font-size: 40px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: var(--charcoal-grey);
  }

  & > section {
    margin: 50px 0 50px;
    h3 {
      font-size: 25px;
      font-weight: 500;
      letter-spacing: -0.31px;
      color: var(--charcoal-grey);
      margin: 0 0 18px;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
      color: var(--battleship-grey);
    }

    img {
      width: 100%;
    }
  }

  .video-container {
    overflow: hidden;
    position: relative;
    width:100%;

    &::after {
      padding-top: 56.25%;
      display: block;
      content: '';
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .download {
    display: flex;
    margin-top: 10px;

    img {
      width: 135px;
    }
  }

  .quick-start {
    video {
      width: 100%;
      margin: 15px 0;
    }
  }

  .boot-camp {
    img {
      margin: 18px 0 27px;
      max-width: 100%;
    }
  }
}