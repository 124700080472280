.create-profile {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-color: var(--ice-blue);
  background-image: url(/img/background-plans.png);

  .title {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #e5e6eb;
  }

  .content {
    width: 100%;
    max-width: 520px;
    height: fit-content;
    padding: 24px 30px 48px;
    border-radius: 3px;
    box-shadow: 0 0 10px 0 rgba(103, 114, 121, 0.1), 0 10px 15px 0 rgba(0, 0, 0, 0.08);
    background-color: var(--white);
    margin-top: 100px;
    font-size: 15px;

    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    
    .avatar {
      position: relative;
      img {
        width: 130px;
        border-radius: 8px;
      }

      label {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -10px;
        top: -10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ffffff;
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;

        &:hover {
          background-color:  rgba(0, 0, 0, 0.1);
        }

        .fa-pen {
          color: #ea694b;
        }
      }

      .avatar-input {
        width: 0;
        height: 0;
      }
    }

    .user-details {
      margin-left: 20px;
    }
  }
}