.multi-words-heading {
  margin: 20px 0;
}

.word-item-row {
  border-top: 1px solid #e5e6eb;
  padding: 5px 0;
  display: flex;
  align-items: center;

  span {
    width: 150px;
  }
}