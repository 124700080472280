.unsubscribe-email {
  display: flex;
  justify-content: center;
  align-items: center;

  .unsubscribing {
    text-align: center;

    h3 {
      margin-top: 10px;
    }
  }

  .unsubscribe-success {
    text-align: center;

    button {
      margin-top: 15px;

      a {
        color: #FFFFFF;
      }
    }
  }

  .unsubscribe-failure {
    text-align: center;

    button {
      margin-top: 15px;

      a {
        color: #FFFFFF;
      }
    }
  }
}