.kt-aside {
  background-color: #242939;
}
.kt-aside .ps > .ps__rail-x {
  background: transparent;
}
.kt-aside .ps > .ps__rail-x:hover,
.kt-aside .ps > .ps__rail-x:focus {
  opacity: 1;
  background: transparent;
}
.kt-aside .ps > .ps__rail-x:hover > .ps__thumb-x,
.kt-aside .ps > .ps__rail-x:focus > .ps__thumb-x {
  opacity: 1;
}
.kt-aside .ps > .ps__rail-x > .ps__thumb-x {
  background: #637099;
  opacity: 1;
}
.kt-aside .ps > .ps__rail-x > .ps__thumb-x:hover,
.kt-aside .ps > .ps__rail-x > .ps__thumb-x:focus {
  opacity: 1;
  background: #637099;
}
.kt-aside .ps > .ps__rail-y {
  background: transparent;
}
.kt-aside .ps > .ps__rail-y:hover,
.kt-aside .ps > .ps__rail-y:focus {
  background: transparent;
  opacity: 1;
}
.kt-aside .ps > .ps__rail-y:hover > .ps__thumb-y,
.kt-aside .ps > .ps__rail-y:focus > .ps__thumb-y {
  opacity: 1;
}
.kt-aside .ps > .ps__rail-y > .ps__thumb-y {
  background: #637099;
  opacity: 1;
}
.kt-aside .ps > .ps__rail-y > .ps__thumb-y:hover,
.kt-aside .ps > .ps__rail-y > .ps__thumb-y:focus {
  opacity: 1;
  background: #637099;
}

.kt-aside-menu {
  background-color: #242939;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-text {
  color: #c0c4d1;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__link {
  background-color: #1f2433;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-text {
  color: #ffffff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--here
  > .kt-menu__link {
  background-color: #1f2433;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--here
  > .kt-menu__link
  .kt-menu__link-text {
  color: #ffffff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link {
  background-color: #1f2433;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-text {
  color: #ffffff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link {
  background-color: #1f2433;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-text {
  color: #ffffff;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__arrow {
  color: #242939;
}
.kt-aside-menu .kt-menu__nav > .kt-menu__separator {
  border-bottom: 1px solid #606a8c;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item
  > .kt-menu__link
  .kt-menu__link-text {
  color: #989eb3;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__link {
  background-color: #1f2433;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--open
  > .kt-menu__link
  .kt-menu__link-text {
  color: #ffffff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--here
  > .kt-menu__link {
  background-color: #1f2433;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__link {
  background-color: #1f2433;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-text {
  color: #ffffff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link {
  background-color: #cdd7dc;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
  > .kt-menu__link
  .kt-menu__link-text {
  color: #ffffff;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  .kt-menu__submenu
  .kt-menu__separator {
  border-bottom: 1px solid #606a8c;
}

.kt-aside
  .kt-aside-menu
  > .kt-menu__nav
  .kt-menu__item
  > .kt-menu__link
  [class^="flaticon2-"],
.kt-aside
  .kt-aside-menu
  > .kt-menu__nav
  .kt-menu__item
  > .kt-menu__link
  [class*=" flaticon2-"] {
  color: #434d6b;
}
