.menu {
  padding: 24px 0 0;

  a {
    display: block;
    padding: 14px 10px 15px 40px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.25px;
    color: var(--bluey-grey);
    cursor: pointer;

    &:hover {
      color: #3b4145;
    }

    &.selected {
      background: #fa694b3a;
      font-weight: 600;
      color: #3b4145;
    }
  }
}
