.data-row {
  display: flex;
  border-bottom: 1px solid #e5e6eb;
  padding: 16px;
  color: #646c9a;
  font-size: 14px;
  align-items: center;

  & > .data-col {
    width: 160px;
    text-align: left;

    @media (min-width: 480px) {
      width: 200px;
    }

    &:last-child {
      flex: 1;
    }
  }

  .heading {
    width: 100px;
    @media (min-width: 480px) {
      width: 156px;
    }
    color: var(--bluey-grey);
    text-align: left;
  }
}