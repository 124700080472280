.account-security-container {
  .title {
    border-bottom: 1px solid #e5e6eb;
    padding-bottom: 8px;
    font-size: 1.7rem;
  }

  .sub-title {
    margin: 16px 0;
  }

  .change-form {
    max-width: 440px;
  }

  .form-group label {
    font-size: 1rem;
  }

  .actions {
    a {
      margin-left: 15px;
    }
  }
}