.edit-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .form-field {
    flex: 1;
  }

  .field-actions {
    margin-left: 20px;
  }
}