/* Footer CSS start */

.kt-login-v2__footer {
  width: 100%;
  min-height: 124px;
  background-color: var(--battleship-grey);
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .elements-part {
    justify-content: space-between;
    @media (max-width: 768px) {
      width: 100% !important;
      flex-direction: column;
    }
  }

  .kt-login-v2__info {
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      text-align: center;
    }

    a {
      margin: 7px 30px 7px 0;
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      letter-spacing: normal;
      color: var(--white);
      @media (max-width: 768px) {
        margin: 3px 0 5px;
      }
    }
    .support {
      @media (max-width: 470px) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .kt-login-v2__link img {
    margin-left: 40px;
    @media (max-width: 768px) {
      margin: 10px 20px;
    }
  }
}
/* Footer CSS end */

/* Terms of Service Css start */
.terms-of-service h1 {
  text-align: center;
}

.terms-of-service h3 {
  letter-spacing: 0;
  margin-top: 0;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  color: #000;
  line-height: 36px;
  font-size: 24px;
}

.terms-of-service p,
.terms-of-service li {
  color: #333333;
  font-size: 16px;
  margin: 0 0 10px;
  letter-spacing: 1.5px;
  line-height: 26px;
  font-family: Roboto, sans-serif;
}

.terms-of-service .goSignIn {
  top: 4%;
  left: 4%;
  transform: translate(-50%, -50%);
}

.terms-of-service .sub-title {
  text-decoration: underline;
  font-style: italic;
}

.terms-of-service a {
  font-size: 17px;
  font-weight: bold;
}
/* Terms of Service Css end */

/* Privacy CSS Start */
.privacy .sub-title {
  text-decoration: underline;
  font-style: italic;
}
/* Privacy CSS End */

@media screen and (max-width: 575px) {
  .kt-login-v2__footer {
    flex-direction: column;
  }
}
