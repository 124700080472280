.modal-dialog {
  &.cls-new-book-modal {
    max-width: 70%;
    width: 70%;
  }
}

.book-search {
  width: 400px;
  margin: 0 auto 30px;
}