.account-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: var(--ice-blue);

  .account-inner {
    background-image: url(/img/background-pattern.png);
  }

  .account-header {
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 50px;
  }

  .account-body {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 0 20px;
  }

  .account-content {
    width: 100%;
    height: fit-content;
    padding: 0 24px 48px;
    border-radius: 3px;
    box-shadow: 0 0 10px 0 rgba(103, 114, 121, 0.1), 0 10px 15px 0 rgba(0, 0, 0, 0.08);
    background-color: var(--white);
    margin-top: 100px;
    margin-bottom: 40px;

    .app-logo {
      display: flex;
      justify-content: center;
      padding: 30px 0;
      img {
        width: 80px;
        height: 80px;
      }
    }
  }
}

.account-bg-color {
  background-image: url(/img/background-characters.png);
}