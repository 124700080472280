.pricing-plans {
  height: 100vh;
  background-size: 300%;
  background-repeat: no-repeat;
  background-position: top;
  background-color: var(--ice-blue);
  background-image: url(/img/background-plans.png);
  padding: 0 15px;

  @media (min-width: 768px) {
    background-size: 100%;
  }

  .logo {
    padding: 19px 0 0 50px;
  }

  .title {
    padding: 61px 0 14px;
    font-family: Roboto;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: -0.35px;
    text-align: center;
    color: var(--white);
  }

  .sub-title {
    font-size: 15px;
    letter-spacing: -0.25px;
    text-align: center;
    color: var(--white);
  }

  .coupon-form {
    width: 100%;
    max-width: 388px;
    margin: 40px auto 0;
    padding: 4px;
    border-radius: 5px;
    background-color: var(--white);

    .inValid-feedback {
      font-size: 12px;
      color: var(--pinkish-orange);
      padding: 0 0 0 16px;
    }

    .submit-btn {
      width: 100%;
      height: 37px;
    }
  }

  .verification-message {
    font-size: 16px;
    margin: 10px 0 15px;
  }

  .plans {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1112px;
    margin: 28px auto 52px;
    min-height: 300px;
  }

  .inclusions {
    width: 100%;
    max-width: 435px;
    margin: 0 auto 30px;
    h3 {
      text-align: center;
    }

    ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
      padding: 0 20px 30px;
    }

    li {
      background: url(/img/bullet.png);
      background-repeat: no-repeat;
      background-position: 0 center;
      border-bottom: 1px solid #e5e6eb;
      padding: 10px 10px 10px 45px;
      font-size: 15px;
    }
  }
}