.youtube-transcripts {
  .search-form {
    display: flex;
    max-width: 600px;

    button {
      width: 300px;
      margin-left: 10px;
    }
  }
}