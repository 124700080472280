/* Booksdetails */

/* DetailsNavBooks */
.dnb-subheader {
  padding: 45px 15px !important;
}

.dnb-subheader-search {
  margin: 0px auto !important;
}

.btt-subheader {
  display: flex;
  /* margin-top: 30px; */
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.tabs.nav.nav-tabs {
  padding-left: 40px;
  height: 40px;
}

.tabs .active {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--carolina-blue) !important;
}

.tabs .nav-item.nav-link {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--charcoal-grey);
  border: none;
}

.tabs .nav-item.active {
  border-bottom: 2px solid var(--carolina-blue) !important;
}
