.email-verify-warning {
  background-color: green;
  margin-top: -4px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-verify-warning span {
  color: white;
  font-family: roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
}

.email-verify-warning span p {
  color: blue;
  text-decoration: underline;
}

.email-verify {
  width: 50%;
  margin: auto;
  margin-top: 100px;
}

.get-token {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.get-token span {
  height: 26px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #3c4146;
}

.verify-email {
  display: flex;
  margin-top: 30px;
}
