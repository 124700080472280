.dictionary-word {
  width: 100%;
  max-width: 548px;
  padding: 0 10px;
  margin: 0 auto;

  .word-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;

    h3 {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
    }
  }

  .word-details {
    padding: 39px 30px 24px;
    border-radius: 3px;
    border: 1px solid #e5e6eb;

    .word-basics {
      display: flex;
      justify-content: center;
    }

    .basics {
      text-align: center;
      color: #3c4146;

      h2 {
        font-size: 4rem;
      }

      .phonetic {
        font-size: 20px;
        font-weight: bold;
      }

      .defs {
        font-size: 16px;
      }
    }

    .chinese-type {
      min-width: 80px;
      color: #91a0aa;
      text-align: center;

      .type-word {
        font-size: 30px;
        font-weight: bold;
      }

      .type {
        font-size: 12px;
      }
    }
  }

  .strokes {
    text-align: center;
  }

  .word-components {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .word-component {
      width: 96px;
      text-align: center;
      padding: 10px;
      border: 1px solid #e5e6eb;
      margin: 0 5px;

      .item-top {
        color: var(--blue);
      }
    
      .item-middle {
        font-size: 12px;
        font-weight: bold;
      }
    
      .item-bottom {
        font-size: 12px;
      }
    }
  }

  .word-mnemonic {
    position: relative;
    height: 176px;
    background-color: #deeaff;
    padding: 30px 20px;
    text-align: center;

    img {
      max-height: 111px;
    }

    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -10px;
      top: -10px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #ffffff;
      box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
      transition: all 0.3s;

      &:hover {
        background-color:  rgba(0, 0, 0, 0.1);
      }

      .fa-pen {
        color: #ea694b;
      }
    }

    .mnemonic-input {
      width: 0;
      height: 0;
    }
  }

  .word-explanation {
    padding: 25px 10px;

    h4 {
      padding: 10px 0;
    }
  }

  .word-definition {
    padding: 25px 10px;

    h4 {
      padding: 10px 0;
    }
  }
}

.word-bottom-sections {
  margin-top: 40px;
  padding: 20px 40px;
  border-top: 1px solid #e5e6eb;
  background-color: #f7f9f9;

  .section {
    margin: 35px 0;
  }

  .word-grid {
    .word-grid-row {
      display: flex;
      border-bottom: 1px solid #e5e6eb;
      padding: 8px 0;
    }

    .word-row {
      span:nth-child(1) {
        width: 10%;
      }
  
      span:nth-child(2) {
        width: 25%;
      }

      span:nth-child(3) {
        width: 25%;
      }
  
      span:nth-child(4) {
        text-align: right;
        flex: 1;
      }
    }
  
    .word-row {
      span:nth-child(1) {
        width: 10%;
      }
  
      span:nth-child(2) {
        width: 25%;
      }
  
      span:nth-child(3) {
        width: 30%;
      }
  
      span:nth-child(4) {
        text-align: right;
        flex: 1;
      }
    }

    .sentence-row {
      span:nth-child(1) {
        width: 30%;
      }
  
      span:nth-child(2) {
        width: 30%;
      }
  
      span:nth-child(3) {
        flex: 1;
      }
    }
  }
}
