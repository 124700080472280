.reset-container {
  color: var(--charcoal-grey);
  font-size: 15px;

  a {
    color: var(--carolina-blue);
  }

  .title {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  .sub-title {
    display: flex;
    text-align: center;
    margin: 18px 0;
    padding-bottom: 10px;
  }

  .reset-form {
    margin-top: 8px;
  }

  .form-control {
    height: 48px;
    &.is-invalid {
      border: 2px solid var(--pinkish-orange);
      background-image: url(/img/basic-alert.png);
    }
  }

  .inValid-feedback {
    font-size: 12px;
    color: var(--pinkish-orange);
    padding: 0 0 0 16px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}