.dictionary-character {
  width: 100%;
  max-width: 548px;
  padding: 0 10px;
  margin: 0 auto;

  .char-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;

    h3 {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
    }
  }

  .char-details {
    padding: 39px 30px 24px;
    border-radius: 3px;
    border: 1px solid #e5e6eb;

    .char-basics {
      display: flex;
      justify-content: space-between;
    }

    .hsk {
      width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .hsk-circled {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      color: #91a0aa;
      border: 2px solid #91a0aa;

      .hsk-label {
        display: inline-block;
        transform: rotate(-90deg);
        font-size: 10px;
        margin-left: 1px;
        margin-right: -5px;
      }

      .hsk-value {
        display: inline-block;
        font-size: 22px;
        width: 14px;
        text-align: right;
        line-height: 38px;
        vertical-align: middle;
        font-weight: bold;
      }
    }

    .hsk-level {
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      margin: 4px 0;
      color: #91a0aa;
    }

    .basics {
      text-align: center;
      color: #3c4146;

      h2 {
        font-size: 4rem;
      }

      .phonetic {
        font-size: 20px;
        font-weight: bold;
      }

      .defs {
        font-size: 16px;
      }
    }

    .char-strokes {
      margin: 10px 0;
    }

    .chinese-type {
      min-width: 80px;
      color: #91a0aa;
      text-align: center;

      .type-char {
        font-size: 30px;
        font-weight: bold;
      }

      .type {
        font-size: 12px;
      }
    }
  }

  .strokes {
    text-align: center;
  }

  .char-components {
    display: flex;
    justify-content: center;

    .char-component {
      width: 96px;
      text-align: center;
      padding: 10px;
      border: 1px solid #e5e6eb;
      margin: 0 5px;

      .item-top {
        color: var(--blue);
      }
    
      .item-middle {
        font-size: 12px;
        font-weight: bold;
      }
    
      .item-bottom {
        font-size: 12px;
      }
    }
  }

  .char-mnemonic {
    display: flex;
    background-color: #deeaff;
    padding: 30px 20px;
    font-family: NewYorkMedium;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;

    span:first-child {
      width: 60%;

      &::before {
        content: "“";
        display: block;
        text-align: center;
        font-size: 42px;
        height: 42px;
        color: #c6d0d6;
      }

      &::after {
        content: "”";
        display: block;
        text-align: center;
        font-size: 42px;
        height: 42px;
        color: #c6d0d6;
      }
    }

    .char-mnemonic-img {
      position: relative;
      height: 176px;
      background-color: #deeaff;
      padding: 30px 20px;
      text-align: center;

      img {
        max-height: 111px;
      }

      label {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -10px;
        top: -10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ffffff;
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;

        &:hover {
          background-color:  rgba(0, 0, 0, 0.1);
        }

        .fa-pen {
          color: #ea694b;
        }
      }

      .mnemonic-input {
        width: 0;
        height: 0;
      }
    }
  }

  .char-explanation {
    padding: 25px 10px;

    h4 {
      padding: 10px 0;
    }
  }

  .char-definition {
    padding: 25px 10px;

    h4 {
      padding: 10px 0;
    }

    .def-item-row {
      border-bottom: 1px solid #e5e6eb;
      padding: 5px 0;
      display: flex;
      justify-content: space-between;

      .def-phonetic {
        width: 150px;
      }

      .def-defs {
        flex: 1;
      }

      .def-edit {
        width: 50px;
        text-align: right;
      }

      .def-delete {
        width: 50px;
        text-align: right;
      }
    }

    .new-def {
      margin: 10px 0;
    }
  }
}

.char-bottom-sections {
  margin-top: 40px;
  padding: 20px 40px;
  border-top: 1px solid #e5e6eb;
  background-color: #f7f9f9;

  .section {
    margin: 35px 0;
  }

  .char-grid {
    .char-grid-row {
      display: flex;
      border-bottom: 1px solid #e5e6eb;
      padding: 8px 0;
    }

    .char-row {
      span:nth-child(1) {
        width: 10%;
      }
  
      span:nth-child(2) {
        width: 25%;
      }

      span:nth-child(3) {
        width: 25%;
      }
  
      span:nth-child(4) {
        text-align: right;
        flex: 1;
      }
    }
  
    .word-row {
      span:nth-child(1) {
        width: 10%;
      }
  
      span:nth-child(2) {
        width: 25%;
      }
  
      span:nth-child(3) {
        width: 30%;
      }
  
      span:nth-child(4) {
        text-align: right;
        flex: 1;
      }
    }

    .sentence-row {
      span:nth-child(1) {
        width: 30%;
      }
  
      span:nth-child(2) {
        width: 30%;
      }
  
      span:nth-child(3) {
        flex: 1;
      }
    }
  }
}
