.mbd-content {
  max-width: 520px;
  margin: auto;
  margin-bottom: 100px;
}

.mbd-subheader {
  display: flex;
  justify-content: space-between;
}

.mbd-description {
  display: flex;
  align-items: center;
  margin-left: -30px;
}

.mbd-avatar {
  margin-left: 8px;
  margin-right: 18px;
}

.mbd-icons {
  display: flex;
  align-items: center;
}

.mbd-icons p {
  margin: 0;
  width: 27px;
  height: 10px;
  font-family: Roboto;
  font-size: 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: center;
  color: #3c4146;
}

.mbd-block-icon,
.mbd-refresh-icon,
.mbd-password-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mbd-block-icon img,
.mbd-refresh-icon img,
.mbd-password-icon img {
  margin-bottom: 4px;
}

.mbd-password-icon {
  margin-left: 32px;
  margin-right: 27px;
}

.mbd-description img.mbd-avatar {
  width: 64px;
  height: 64px;
}

.mbd-pInfo p.mbd-name {
  width: 83px;
}
/* public style */

.mbd-profile-details,
.mbd-details,
.mbd-subscription,
.mbd-activities {
  margin-top: 16px;
}

.mbd-profile-details > div,
.mbd-details > div,
.mbd-subscription > div,
.mbd-activities div {
  display: flex;
  height: 48px;
  border-bottom: 1px solid #e5e6eb;
}

.mbd-profile-details img,
.mbd-details img,
.mbd-subscription img,
.mbd-activities img {
  width: 26px;
  height: 24px;
  margin-right: 7px;
  object-fit: contain;
}

.mbd-pInfo > p,
.mbd-details > p,
.mbd-subscription > div > p,
.mbd-activities p {
  height: 26px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #3c4146;
}

.mbd-title {
  width: 30%;
}
.mbd-content-details {
  width: 45%;
}

.mbd-title,
.mbd-content-details {
  display: flex;
  align-items: center;
}

.mbd-others {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

p {
  margin: 0;
}

.mbd-title p,
.mbd-others p {
  height: auto;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #91a0aa;
}

.mbd-content-details p {
  height: auto;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #3c4146;
}

.mbd-email .mbd-content-details p,
.mbd-book_text .mbd-content-details p {
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #82afff;
}

.profileBody {
  padding-left: 260px;
  padding-top: 80px;
}

@media (max-width: 1024px) {
  .profileBody {
    padding-left: 25px;
    padding-top: 80px;
    padding-right: 10px;
  }
}

.plan-expire-popover .Cal__Header__date {
  position: relative;
}
