.book-details {

  .section-top {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
  }

  .header {
    display: flex;
    align-items: center;
    margin-left: -36px;
    margin-bottom: 15px;

    img {
      width: 31px;
      height: 45px;
    }

    h3 {
      margin: 10px;
      flex: 1;
    }
  }

  .form-row {
    margin-bottom: 0;
    padding: 8px 0;
    border-bottom: 1px solid #e5e6eb;

    label {
      font-size: 1em;
    }

    .upload-btn {
      cursor: pointer;
      font-weight: 600;
    }

    .full-width {
      width: 100%;
    }
  }

  .section-bottom {
    .tabs {
      margin: 60px 0;
    }
  }
}

.book-cover-field {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f9f9;
    flex: 1 1;
    padding: 15px 0;

  .book-cover-input {
    display: none;
    position: relative;
  }

  .upload-btn {
    cursor: pointer;
    margin: 10px 0;
    font-weight: 600;
  }
}