.char-definition {
  padding: 25px 10px;

  h4 {
    padding: 10px 0;
  }

  .def-item-row {
    border-bottom: 1px solid #e5e6eb;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;

    .def-phonetic {
      width: 150px;
    }

    .def-defs {
      flex: 1;
    }

    .def-edit {
      width: 50px;
      text-align: right;
    }

    .def-delete {
      width: 50px;
      text-align: right;
    }
  }

  .new-def {
    margin: 10px 0;
  }
}