.device-config__config {
  h4 {
    margin: 16px 0;
  }
  .form-group {
    margin-bottom: 8px;
  }

  .save-btn {
    margin-left: 10px;
  }
}