.youtube-url-form {
  .horizontal-form {
    display: flex;
    justify-content: space-between;
  }

  .inline-actions {
    margin-top: 0;
  }

  .url-field {
    flex: 1;
  }

  .inline-actions {
    width: 100px;
    margin-left: 10px;
  }

  .form-row {
    border-bottom: 0 !important;
  }
}