.device-config__texts {
  .text-actions {
    display: flex;
    justify-content: space-between;

    a {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}