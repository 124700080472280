.size-24-24 {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.m-0 {
  margin: 0;
}
.mr-16 {
  margin-right: 16px;
}
.mr-20 {
  margin-right: 20px;
}
.mb--85 {
  margin-bottom: -85px;
}

.align-right {
  text-align: right;
}

/* Modal */

.modal-header {
  background-color: #fa694b;
}

.modal .modal-content .modal-header .modal-title {
  color: #FFFFFF;
  font-size: 1.5rem;
  font-weight: 600;
}
