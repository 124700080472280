.classes {
  .header {
    display: flex;
    align-items: center;

    .classes-search {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
    }

    .search-bar {
      margin-right: 10px;
    }

    .search-input {
      width: 360px;
      height: 36px;
      padding: 8px 10px 8px 20px;
      border-radius: 3px;
      background-color: var(--ice-blue);
      border: 0;
      position: relative;
    }
  }
  .classes-results {
    margin-top: 40px;

    .classes-grid {
      font-size: 1em;

      .col-name {
        img {
          width: 24px;
        }
      }
    }
  }
}
