.new-discovery-video {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  
  .header {
    display: flex;
    align-items: center;
    margin-left: -36px;
    margin-bottom: 15px;

    img {
      width: 31px;
      height: 45px;
    }

    h3 {
      margin: 10px;
      flex: 1;
    }
  }

  .sub-section {
    padding: 20px 0;
    border-top: 1px solid #e5e6eb;
  }

  .form-row {
    margin-bottom: 0;
    padding: 8px 0;
    border-bottom: 1px solid #e5e6eb;

    label {
      font-size: 1em;
    }
  }

  .actions {
    margin-top: 15px;
    text-align: right;

    button {
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
}
