.new-class-modal {
  .form-row {
    margin-bottom: 0;
    padding: 8px 0;
    border-top: 1px solid #e5e6eb;
  }

  .form-label {
    font-size: 1rem;
  }
}