.page-detail-modal {
  .field {
    margin-bottom: 10px;
    label {
      margin-bottom: 0;
    }

    .value {
      font-size: 16px;
    }

    .thumbnail {
      img {
        max-width: 100%;
      }
    }
  }


}
