.profile {

  .header {
    display: flex;
    align-items: center;
    margin: 15px auto;
    width: 100%;
    max-width: 520px;
    
    .avatar {
      position: relative;
      img {
        width: 48px;
        border-radius: 50%;
      }

      label {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -10px;
        top: -10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ffffff;
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;

        &:hover {
          background-color:  rgba(0, 0, 0, 0.1);
        }

        .fa-pen {
          color: #ea694b;
        }
      }

      .avatar-input {
        width: 0;
        height: 0;
      }
    }

    .user-name {
      font-size: 20px;
      margin-left: 20px;
    }
  }

  .user-details {
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
  }

  .tabs {
    margin: 28px auto;

    nav {
      display: flex;
      justify-content: left;
      width: 100%;
      max-width: 520px;
      margin: 0 auto;
    }

    .nav-item {

      &.nav-link {
        font-weight: 600;
      }
    }

    .subscription {
      width: 100%;
      max-width: 520px;
      margin: 0 auto;
    }
  }
}

.settings {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .settings-left {
    width: 100%;

    @media (min-width: 768px) {
      width: 220px;
    }

    .header {
      display: flex;
      position: relative;
      margin: 0 0 20px 0;

      .back-btn {
        position: absolute;
        top: 12px;
        left: -30px;
      }

      .avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
        margin-right: 10px;
      }

      h3 {
        margin: 0;
      }
    }
  }

  .settings-right {
    flex: 1;
    max-width: 735px;
    padding-top: 68px;

    @media (min-width: 768px) {
      margin-left: 20px;
    }

    .user-details {
      width: 100%;
      max-width: 520px;
    }
  }
}