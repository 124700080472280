.search-header {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 20px;
    color: #3c4146;
  }

  .search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  }
}