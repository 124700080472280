.mb-image-content img {
  width: 24px;
  height: 24px;
}

.mb-image-content {
  display: flex;
  align-items: center;
}

.mb-image-content p {
  /* display - when premium is null */
  margin: auto 0;
}

.react-bootstrap-table-pagination {
  float: right;
  width: 50%;
}
.react-bootstrap-table table {
  border: none;
  table-layout: auto;
}
.react-bootstrap-table table th {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #667279;
  text-align: left;
  outline: none;
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid #cdd1d8;
}

.react-bootstrap-table thead tr {
  background-color: lightgray;
}

.react-bootstrap-table table td {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #3b4145;
  text-align: left;
  vertical-align: middle;
  outline: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #e5e6eb;
}

.mb-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  width: 100%;
}

.mb-rowsPerPage {
  width: 74px;
  height: 15px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: #3c4146;
}

#itemsofPerPage {
  margin-left: 6px;
  outline: none;
  border: none;
  background: url(/img/expand.svg) no-repeat right;
  width: 24px;
  height: 24px;
  object-fit: contain;
  -webkit-appearance: none;
  background-position-x: 4px;
}

.mb-currentList {
  margin-left: 70px;
  height: 15px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: #3c4146;
}

.mb-pagination-pages #rightIcon {
  margin-left: 29px;
}

.mb-user-details {
  width: 36px;
  height: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: right;
  color: #82afff;
  margin: 0;
  float: right;
}

.mb-filter-role,
.mb-filter-joined {
  width: 57px;
  height: 32px;
  border-radius: 3px;
  border: solid 1px #cdd7dc;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #47c9a2;

  &.filter-selected {
    background: #fa694b;
  }
}

.mb-filter-buttons {
  display: flex;
}

.mb-filter-role p,
.mb-filter-joined p {
  margin: 0;
  width: 24px;
  height: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #91a0aa;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black
}
/* custom css */

.size-24-24 {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

td.roleName {
  width: 41px;
  height: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #3c4146;
}
.dropdown-menu.mb-filterRole-dropdown {
  top: 12px !important;
  padding: 0px 8px;
}
td.align-right {
  width: 26px;
  height: 15px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: right;
  color: #91a0aa;
}

.roleFilterAction-clear {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 32px;
  height: 19px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  /* color: #cdd7dc; */
  color: red;
}

.roleFilterAction-save {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 32px;
  height: 19px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  /* color: #82afff; */
  color: green;
}

.joined-popover .popover {
  top: 12px !important;
  left: -15px !important;
  width: 280px;
}

.role-popover .popover {
  top: 12px !important;
  left: -15px !important;
}

.popover-body {
  padding: 0;
}

.roleFilterContent {
  padding: 0 13px 0 8px;
}

.mb-calendar-confirm {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mb-calendar-confirm span {
  margin: auto 16px;
  cursor: pointer;
}

.mb-calendar-confirm span:first-child {
  height: 19px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: red;
}

.mb-calendar-confirm span:last-child {
  height: 19px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: right;
  color: green;
}

/* Calendar customize */
.Cal__Container__root {
  width: 100% !important;
}

.Cal__MonthList__root {
  height: 311px !important;
}

.Cal__Header__dateWrapper.Cal__Header__day {
  font-size: 15px;
}

.Cal__Today__root.Cal__Today__show {
  display: none;
}

.Cal__Weekdays__day {
  padding: 0;
}

.Cal__Header__root {
  min-height: 0;
  padding: 0;
}

.Cal__Header__range .Cal__Header__wrapper:last-child {
  margin-left: -10px;
  margin-right: 20px;
}

.Cal__Header__wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  cursor: pointer;
  align-items: center;
  justify-content: space-evenly;
  margin-left: -40px;
}

@media (min-width: 768px) {
  .kt-members-subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mb-pagination-pages {
    margin-left: 72px;
  }

  .kt-members-subheader .kt-refresh {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .kt-members-subheader .kt-refresh img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .kt-members-subheader .kt-refresh p {
    width: 37px;
    height: 10px;
    font-family: Roboto;
    font-size: 8px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    text-align: center;
    color: #3c4146;
    margin-top: 4px;
    margin-bottom: 0;
  }

  .mb-searchbox-filter {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .kt-total-members {
    margin-top: 0px;
  }
}

@media (max-width: 768px) {
  .kt-members-subheader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mb-pagination-pages {
    margin-left: 0px;
  }

  .kt-refresh {
    display: none;
  }

  .mb-searchbox-filter {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .kt-total-members {
    margin-top: 10px;
  }

  .kt-input-icon {
    margin: 10px;
  }
}
