.payment-container {
  .payment-row {
    position: relative;
    padding-top: 60px;

    @media (min-width: 780px) {
      padding-top: 0;
    }

    .goSignIn {
      position: absolute;
      top: 35px;
      z-index: 99;
    }

    .card-img {
      width: 100%;
      max-width: 350px;
      margin: 30px 0 10px;
    }

    .payment-title {
      font-weight: 500;
      font-size: 22px;
      margin-top: 25px;
    }
  }

  .payment-form {
    width: 100%;
    margin: 0 auto 80px;
    max-width: 680px;

    .payment-details {
      padding: 0 25px;

      .payment-btn {
        text-align: center;
      }
    }
  }
}

.subscribe-success {
  .card-body {
    h2 {
      text-align: center;
      margin: 30px 30px 15px 30px;
      font-family: Roboto;
      font-size: 42px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.25px;
      text-align: center;
      color: #fa694b;
    }

    p {
      font-size: 1.4rem;
    }

    button {
      margin: 40px 0;
      font-size: 1.2rem;
    }
  }

  .success-img {
    height: 50vh;
  }
}
