.home-page {

  .elements-part {
    width: 100%;
    max-width: 1240px;
    padding: 20px;

    @media (min-width: 768px) {
      padding: 25px 30px 0 ;
    }

    @media (min-width: 1024px) {
      padding: 30px 45px 0;
    }
  }

  .menubar {
    .elements-part {
      @media (min-width: 768px) {
        padding: 25px 30px 25px;
      }
    }
  }

  .cta-btn {
    width: 300px;
    height: 60px;
    margin: 30px 0 10px;
    padding: 14px 16px;
    border-radius: 3px;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(130, 175, 255, 0.25);
    background-color: var(--carolina-blue);
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.25px;
    text-align: center;
    color: var(--white);
    @media (max-width: 768px) {
      margin: 20px 0 5px;
    }
  }

  .introduction-part {
    background-color: #ff7346;
    padding: 35px;
    box-sizing: border-box;
    position: relative;

    @media (min-width: 768px) {
      flex-direction: row;
      height: 680px;
    }

    .elements-part {
      flex-direction: column-reverse;
      @media (min-width: 768px) {
        flex-direction: row;
      }

      .text-part {
        width: 100%;
        @media (min-width: 768px) {
          width: 50%;
        }

        .The-most-efficient-w {
          font-family: Montserrat;
          font-size: 26px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: -0.5px;
          color: var(--white);
          margin-bottom: 20px;
          text-align: center;
          @media (min-width: 768px) {
            text-align: left;
            font-size: 36px;
          }
          @media (min-width: 1024px) {
            font-size: 50px;
          }
        }
        .Learn-Chinese-all-th {
          font-family: Roboto;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: var(--white);
          margin-bottom: 51px;
          text-align: center;
          @media (min-width: 768px) {
            text-align: left;
            font-size: 20px;
          }
        }
        .mobile-part {
          display: flex;
          flex-direction: column;
          margin-bottom: 13px;

          @media (max-width: 768px) {
            flex-direction: row;
            justify-content: left;
          }

          @media (max-width: 480px) {
            flex-direction: row;
            justify-content: center;
          }

          img {
            width: 166px;
            height: 50px;
          }
          .apple-image {
            margin-bottom: 21px;
          }
        }
        .Basic-knowledge-of-C {
          height: 57px;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: normal;
          color: #000000;
          text-align: center;
          @media (min-width: 768px) {
            width: 264px;
            text-align: left;
          }

          a {
            color: #000000;
            text-decoration: underline;
          }
        }
      }

      .image-part {
        img {
          width: 100%;
          max-width: 600px;
        }
      }
    }
  }

  .intro-video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 30px;
    background-color: #ea6236;

    .video {
      width: 100%;
      max-width: 840px;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      video {
        width: 100%;
      }

      img {
        position: absolute;
        z-index: 1;
        border: 0;
        background: transparent;
        width: 140px;
      }
    }

    .cta-part {
      margin-top: -15px;
    }
  }

  .video-part {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin: 70px 0 20px;
      font-family: Montserrat;
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.5px;
      color: var(--charcoal-grey);
      @media (max-width: 768px) {
        font-size: 30px;
      }
    }

    p {
      max-width: 500px;
      margin-bottom: 28px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.7;
      letter-spacing: normal;
      text-align: center;
      color: var(--battleship-grey);
    }

    .video-container {
      background-image: url("/img/image-e.svg");
      height: 100%;
      max-height: 474px;
      width: 100%;
    }

    .video-introduction {
      overflow: hidden;
      position: relative;
      max-width: 843px;
      width:100%;
      margin: 0 auto;
  
      &::after {
        padding-top: 56.25%;
        display: block;
        content: '';
      }
  
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .texts-and-lessons {
    background-color: var(--ice-blue);

    .elements-part {

      .image-part {
        width: 50%;
        @media (max-width: 768px) {
          width: 40%;
        }
      }
      .text-part {
        margin-left: 100px;
        width: 50%;
        @media (max-width: 768px) {
          margin-left: 10px;
          width: 60%;
        }

        .title {
          margin: 0 0 20px;
          font-family: Montserrat;
          font-size: 40px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.5px;
          color: var(--charcoal-grey);
          @media (max-width: 768px) {
            font-size: 30px;
          }
        }
        .content {
          font-family: Roboto;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.7;
          letter-spacing: normal;
          color: var(--battleship-grey);
          @media (max-width: 768px) {
            font-size: 15px;
          }
        }
      }
    }
  }

  .study-your-content {
    background-color: var(--ice-blue);

    .image-part {
      width: 50%;
      @media (max-width: 768px) {
        width: 40%;
      }
    }
    .text-part {
      margin-right: 100px;
      width: 50%;
      @media (max-width: 768px) {
        margin-right: 10px;
        width: 60%;
      }

      .title {
        margin: 0 0 20px;
        font-family: Montserrat;
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.5px;
        color: var(--charcoal-grey);
        @media (max-width: 768px) {
          font-size: 30px;
        }
      }
      .content {
        font-family: Roboto;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.7;
        letter-spacing: normal;
        color: var(--battleship-grey);
        @media (max-width: 768px) {
          font-size: 15px;
        }
      }
    }
  }

  .dictionary-part {
    .elements-part {
      display: flex;
      @media (max-width: 768px) {
        flex-direction: column;
      }

      .part-1 {
        margin-right: 100px;
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
          margin-right: 0px;
        }
      }
      .part-2 {
        margin-left: 100px;
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
          margin-left: 0px;
        }
      }

      .content-detail {
        text-align: center;
        @media (min-width: 1024px) {
          text-align: left;
        }
      }

      img {
        width: 70px;
        height: 70px;
        margin-bottom: 10px;
      }
      .title {
        font-family: Montserrat;
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.38px;
        color: var(--charcoal-grey);
        margin-bottom: 10px;
      }
      .content {
        font-family: Roboto;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.7;
        letter-spacing: normal;
        color: var(--battleship-grey);
      }
      .content-detail-1, .content-detail-3 {
        margin: 141px 0 94px;
        min-height: 200px;
        @media (max-width: 768px) {
          margin: 50px 0 30px;
        }
      }
      .content-detail-2, .content-detail-4 {
        margin-bottom: 131px;
        min-height: 200px;
        @media (max-width: 768px) {
          margin: 50px 0 30px;
        }
      }
      .content-detail-4 .title{
        padding-top: 80px;
      }
    }
  }

  .who-can-use-fullchi {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;

    @media (min-width: 768px) {
      padding: 0 30px;
    }

    @media (min-width: 1024px) {
      padding: 0 45px;
    }

    .title {
      margin: 120px 0 9px;
      font-family: Montserrat;
      font-size: 50px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: -0.5px;
      text-align: center;
      color: var(--charcoal-grey);
      @media (max-width: 768px) {
        font-size: 30px;
      }
    }
    .content {
      font-family: Roboto;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.7;
      letter-spacing: normal;
      text-align: center;
      color: var(--battleship-grey);
    }
    .pinyin, .few-characters {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 36px 80px;
      @media (max-width: 768px) {
        margin: 5px;
      }

      img {
        margin-bottom: 20px;
      }
      p {
        font-family: Roboto;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: center;
        color: var(--charcoal-grey);
      }
    }
    .learn-more {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .introduct-part {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1000px;
        margin: 10px;
        padding: 10px;
        border-radius: 16px;
        border: solid 1px var(--light-blue-grey);
        flex-direction: column;

        @media (min-width: 768px) {
          margin: 20px 30px;
          padding: 40px;
          flex-direction: row;
        }

        .image-texts {
          display: flex;
          align-items: center;
          width: 100%;

          .content-details {
            margin-left: 40px;
            @media (max-width: 768px) {
              margin-left: 10px;
              max-width: 250px;
            }

            .learn-title {
              font-family: Montserrat;
              font-size: 25px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: -0.38px;
              color: var(--charcoal-grey);

              @media (min-width: 768px) {
                font-size: 30px;
              }

            }
            .learn-content {
              font-family: Roboto;
              font-size: 18px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.42;
              letter-spacing: normal;
              color: var(--charcoal-grey);

              @media (min-width: 768px) {
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }

  .short-learning-time {
    background-color: var(--ice-blue);
    margin-top: 100px;

    .elements-part {
      flex-direction: column;
      @media (min-width: 768px) {
        flex-direction: row;
        padding: 35px 30px 35px;
      }

      .text-part {
        width: 50%;
        padding: 0 50px 0 100px;
        @media (max-width: 768px) {
          width: 90%;
          padding: 0 20px 20px 20px;
        }

        .title {
          margin: 0 0 20px;
          font-family: Montserrat;
          font-size: 40px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.5px;
          color: var(--charcoal-grey);
        }
        .content {
          margin: 20px 0 0;
          font-family: Roboto;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.7;
          letter-spacing: normal;
          color: var(--battleship-grey);
        }
      }
      .image-part {
        width: 50%;
        @media (max-width: 768px) {
          width: 90%;
        }
      }
    }
  }

  .all-in-one {
    background-image: url("/img/background-h.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 600px;

    .elements-part {
      @media (max-width: 768px) {
        flex-direction: column;
      }

      .title-part {
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
          text-align: center;
        }

        .title {
          margin: 0 150px 30px 0;
          font-family: Montserrat;
          font-size: 50px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: -0.5px;
          color: var(--white);
          @media (max-width: 768px) {
            margin: 20px 0 20px 0;
          }
        }
        .content {
          margin: 30px 150px 76px 0;
          font-family: Roboto;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: var(--white);
          @media (max-width: 768px) {
            margin: 10px 0 30px 0;
          }
        }
      }
      .flow-part {
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
          text-align: center;
        }

        .content {
          margin: 0 0 20px;
          padding: 25px 0;
          font-family: Roboto;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: var(--white);
        }
        .name {
          margin-top: 30px;
          font-family: Montserrat;
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: -0.3px;
          color: var(--white);
        }
        .job {
          opacity: 0.6;
          margin-bottom: 50px;
          font-family: Roboto;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: var(--white);
        }
      }
    }
  }

  .mobile-introduct {

    .elements-part {
      flex-direction: column;
      padding: 25px 20px 0 20px;
      @media (min-width: 768px) {
        flex-direction: row;
      }

      .text-part {
        margin-right: 150px;
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
          margin-right: 0;
          text-align: center;
        }

        img {
          @media (max-width: 768px) {
            width: 100px;
          }
        }
        p {
          margin-top: 30px;
          font-family: Montserrat;
          font-size: 40px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.5px;
          color: var(--charcoal-grey);
          @media (max-width: 768px) {
            font-size: 30px;
            margin-bottom: 20px;
          }
        }
        button {
          width: 300px;
          height: 60px;
          margin: 30px 301px 10px 1px;
          padding: 18px 21px;
          border-radius: 3px;
          border: none;
          box-shadow: 0 2px 4px 0 rgba(130, 175, 255, 0.25);
          background-color: var(--carolina-blue);
          font-family: Roboto;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.25px;
          text-align: center;
          color: var(--white);
          @media (max-width: 768px) {
            margin: 0;
          }
        }
        .mobile-part {
          display: flex;
          flex-direction: column;

          img { 
          width: 170px;
          height: 50px;
          margin: 10px 0;
          }       
        }

        .download {
          display: flex;
          margin: 30px 0;
          justify-content: center;

          @media (min-width: 769px) {
            justify-content: left;
          }

          @media (min-width: 1025px) {
            display: none;
          }
      
          img {
            width: 155px;
            padding-right: 5px;
          }
        }
      }

      .image-part {
        width: 50%;
        @media (max-width: 768px) {
          width: 70%;
        }
      }
    }
  }

  .kt-login-v2__footer {
    .elements-part {
      padding: 20px 20px 30px;

      @media (min-width: 768px) {
        padding: 40px 20px 60px;
      }

      @media (min-width: 1024px) {
        padding: 60px 45px 90px;
      }
    }
  }
}
