.member-detail {
  max-width: 548px;
  width: 100%;
  margin: 0 auto 30px;

  .row {
    display: flex;
    height: 48px;
    border-bottom: 1px solid #e5e6eb;
    align-items: center;

    .label {
      color: #91a0aa;
    }

    .col1 {
      width: 30%;
    }

    .col2 {
      width: 40%;
    }

    .col3 {
      width: 30%;
    }
  }
}