.class-detail-container {
  .class-detail {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
  
    .header {
      display: flex;
      align-items: center;
      margin-left: -36px;
      margin-bottom: 15px;
  
      img {
        width: 31px;
        height: 45px;
      }
  
      h3 {
        margin: 10px;
        flex: 1;
      }
  
      .delete-link {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        &:hover {
          text-decoration: none;
        }
  
        span {
          font-size: 8px;
          font-weight: 500;
          letter-spacing: .25px;
          text-align: center;
          color: #3c4146;
        }
      }
    }
  
    .form-row {
      margin-bottom: 0;
      padding: 8px 0;
      border-bottom: 1px solid #e5e6eb;
  
      label {
        font-size: 1em;
      }
  
      .inValid-feedback {
        font-size: 12px;
        color: var(--pinkish-orange);
        padding: 0;
      }
  
      .org-img {
        max-width: 200px;
      }
    }
  }

  .tabs {
    margin: 60px 0;
  }
}
