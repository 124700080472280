.member-profile-basic {
  max-width: 548px;
  width: 100%;
  margin: 0 auto 30px;

  .header {
    display: flex;
    align-items: center;

    .avatar {
      width: 64px;
      height: 64px;
      margin: 0 18px 0 8px;
    }
  }

  .details {
    .row {
      display: flex;
      height: 48px;
      border-bottom: 1px solid #e5e6eb;
      align-content: center;

      .col1 {
        width: 30%;
      }

      .col2 {
        width: 70%;
      }
    }
  }

}