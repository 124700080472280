#changePassword p {
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.25px;
    color: #3c4146;
}

.member-detail-tabs {
    .nav-item.nav-link {
        width: 120px;
    }
}