.user-layout {
  display: flex;

  .left {
    display: none;

    @media (min-width: 1024px) {
      display: block;
      width: 240px;
    }
  }

  .right {
    flex: 1;
  }

  .content-container {
    max-height: calc(100vh - 65px);
    overflow-y: scroll;

    .content {
      padding: 33px 38px 33px 42px;
    }
  }
}