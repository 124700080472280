.billing-plans {
  display: flex;
  flex-wrap: wrap;

  .card {
    width: 100%;
    background-color: var(--ice-blue);
    padding: 16px 24px;
    margin: 5px;

    @media (min-width: 768px) {
      width: 47%;
    }

    @media (min-width: 1024px) {
      width: 31%;
    }

    h4 {
      font-size: 1.3rem;
    }

    .date-block {
      font-weight: 600;
      font-size: 1.4rem;
      margin: 2px 0;
    }
  }
}

.non-web-payments {
  font-size: 1.2rem;

  p {
    margin-bottom: 15px;
  }
}