.video-texts-add-video {
  .text-search {
    margin: 0 auto 10px;
  }
  .records-info {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin: 10px 0;
  }
}
