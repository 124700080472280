.vertical-nav {
  .section {
    margin-bottom: 16px;
    list-style: none;
    border: 1px solid #e5e6eb;
    border-radius: 6px;

    .menu-heading {
      padding: 8px 16px;
      margin-top: 0;
      margin-bottom: 0;
      font-size: inherit;
      font-weight: 600;
      border-bottom: 1px solid #e5e6eb;
    }

    .menu-item {
      display: block;
      position: relative;
      padding: 8px 16px;
      border-bottom: 1px solid #e5e6eb;
      color: var(--gray-dark);

      &.selected {
        cursor: default;
        background-color: var(--ice-blue);

        &:before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 2px;
          content: "";
          background-color: var(--pinkish-orange);
        }
      }

      &:hover {
        background-color: var(--ice-blue);
      }
    }
  }
}