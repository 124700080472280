.new-organization {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;

  .header {
    display: flex;
    align-items: center;
    margin-left: -36px;
    margin-bottom: 15px;

    img {
      width: 31px;
      height: 45px;
      margin-right: 5px;
    }

    h3 {
      margin: 10px;
    }
  }

  .form-row {
    margin-bottom: 0;
    padding: 8px 0;
    border-bottom: 1px solid #e5e6eb;

    label {
      font-size: 1em;
    }

    .inValid-feedback {
      font-size: 12px;
      color: var(--pinkish-orange);
      padding: 0;
    }

    .org-img {
      max-width: 200px;
    }
  }

  .actions {
    text-align: right;
    margin-top: 30px;
    button + button {
      margin-left: 10px;
    }
  }
}